import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import CookieModal from './component/cookies/CookieModal';
import './static/css/common.css'
import { Route, Routes } from 'react-router-dom';
import {useLocation} from 'react-router-dom'
import { AnimatePresence } from "framer-motion";
// import Zendesk, { ZendeskAPI } from "./ZendexConfig";
import Layout from './component/layout/Layout';
import HomePage from './component/pages/HomePage';
import PersonalAccount from './component/pages/PersonalAccount';
import SavingsAcoount from './component/pages/SavingsAcoount';
import BuisnessAccount from './component/pages/BuisnessAccount';
import Crypto from './component/pages/Crypto';
import AboutUs from './component/pages/AboutUs';
import Cards from './component/pages/Cards';
import ContactUs from './component/pages/ContactUs';
import Blog from './component/pages/Blog';
import DigitalWallet from './component/pages/DigitalWallet';
import Careers from './component/pages/Careers';
import TermsOfUse from './component/pages/TermsOfUse';
import PrivacyPolicy from './component/pages/PrivacyPolicy';
import Login from './component/accounts/Login';
import Register from './component/accounts/Register';
// import RegisrterPersonalAccStepOne from './component/accounts/RegisrterPersonalAccStepOne';
import RegisrterPersonalAccStepTwo from './component/accounts/RegisrterPersonalAccStepTwo';
import RegisrterPersonalAccStepThree from './component/accounts/RegisrterPersonalAccStepThree';
// import RegisrterBuisnessAccStepOne from './component/accounts/RegisrterBuisnessAccStepOne';
import RegisrterBuisnessAccStepTwo from './component/accounts/RegisrterBuisnessAccStepTwo';
import RegisrterBuisnessAccStepThree from './component/accounts/RegisrterBuisnessAccStepThree';
import RegisrterBuisnessAccStepFour from './component/accounts/RegisrterBuisnessAccStepFour';
import PersonalAccRegisterForm from './component/accounts/PersonalAccRegisterForm';
import BuisnessAccRegisterForm from './component/accounts/BuisnessAccRegisterForm';
import SavingsAccRegisterForm from './component/accounts/SavingsAccRegisterForm';
import AccountActivation from './component/accounts/AccountActivation';
import ScrollToTop from './component/scrollToTop';
import AccountVerification from './component/accounts/AccountVerification';
import BWMainPage from './component/bankWallet/BWMainPage';
import WalletLayout from './component/layout/WalletLayout';
import CryptoDetails from './component/bankWallet/CryptoDetails';
import FiatDashboard from './component/bankWallet/FiatDashboard';
import FiatDetails from './component/bankWallet/FiatDetails';
import WalletCrypto from './component/bankWallet/WalletCrypto';
import Swap from './component/bankWallet/Swap';
import CardDetails from './component/bankWallet/CardDetails';
import Payment from './component/bankWallet/Payment';
import FiatTransfer from './component/bankWallet/FiatTransfer';
import PaymentConfirmation from './component/bankWallet/PaymentConfirmation';
import Account from './component/bankWallet/Account';
import NotFound from './component/NotFound';
import NotAccessPage from './component/Authentication/NotAccessPage';
import ProtectedRoute from './component/Authentication/ProtectedRoute';
import ForgotPassword from './component/accounts/ForgotPassword/ForgotPassword';
import SetNewPassword from './component/accounts/ForgotPassword/SetNewPassword';
import PasswordSuccessView from './component/accounts/ForgotPassword/PasswordSuccessView';
import BlogDetails from './component/pages/BlogDetails';
import RecievedCrypto from './component/bankWallet/RecievedCrypto';
import SendCrypto from './component/bankWallet/SendCrypto';
import SendViaCryptoNetwork from './component/bankWallet/SendViaCryptoNetwork';
import SendViaEmailPhoneId from './component/bankWallet/SendViaEmailPhoneId';
import BiilsAndPayments from './component/bankWallet/BiilsAndPayments';
import BillSuccess from './component/bankWallet/BillSuccess';
import ConfirmSwap from './component/bankWallet/ConfirmSwap';
import SwapSuccess from './component/bankWallet/SwapSuccess';
import ChangeCardPin from './component/bankWallet/ChangeCardPin';
import RecipientsManagment from './component/bankWallet/RecipientsManagment';
import BillsManagment from './component/bankWallet/BillsManagment';
import TransactionsHistory from './component/bankWallet/TransactionsHistory';
import ApplyForUpgrade from './component/bankWallet/ApplyForUpgrade';
import ChangePhNumber from './component/bankWallet/ChangePhNumber';
import BlogStructureNew from './component/pages/BlogStructureNew';
import BlogDetailsStructure from './component/pages/BlogDetailsStructure';
import Notifications from './component/bankWallet/Notifications';
import ExchangeRate from './component/pages/ExchangeRate';
import SendToCountry from './component/pages/SendToCountry';
import SecuirityBugs from './component/pages/SecuirityBugs';
import Complaints from './component/pages/Complaints';
import LegalTermsPolicy from './component/pages/LegalTermsPolicy';
// const ZENDESK_KEY = "68fd4002-6088-4057-ba23-9d447044ae2c";
import axios from 'axios';
import FiatSwap from './component/bankWallet/FiatSwap';
import Security from './component/pages/Security';
import VerifyPassword from './component/accounts/VerifyPassword';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConvertHistory from './component/bankWallet/ConvertHistory';
import TransactionCryptoHistory from './component/bankWallet/TransactionCryptoHistory';
import Signup from './component/accounts/Registration/Initial/Signup';
import AccountSelection from './component/accounts/Registration/Initial/AccountSelection';
import PersonalAcc from './component/accounts/Registration/personal/PersonalAcc';
import BuisnessAcc from './component/accounts/Registration/Buisness/BuisnessAcc';
import ApplicationRecevied from './component/accounts/Registration/ApplicationRecevied';
function App() {
  const location = useLocation();

  
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const checkAcceptance = async () => {
      try {
        // Check if the user has accepted the policy
        const acceptedPolicy = localStorage.getItem('acceptedPolicy');
        const policyExpiration = localStorage.getItem('policyExpiration');
        const now = new Date().getTime();

        if (acceptedPolicy !== 'true' || (policyExpiration && now > parseInt(policyExpiration))) {
          setModalOpen(true);
        }
      } catch (error) {
        console.error('Error checking acceptance:', error);
      }
    };

    checkAcceptance();
  }, []);

  const closeModal = async () => {
    setModalOpen(false);

    try {
      // Save policy acceptance in localStorage
      localStorage.setItem('acceptedPolicy', 'true');
      // Set a timestamp for when the policy was accepted
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 15); // 15 days expiration
      localStorage.setItem('policyExpiration', expirationDate.getTime());
    } catch (error) {
      console.error('Error saving acceptance:', error);
    }
  };

  return (
    <div>
        <ScrollToTop/>
      <ToastContainer 
        position="top-right"
        autoClose={3000} // Toast will auto close after 5000ms (5 seconds)
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
        <AnimatePresence mode='wait'>
        

        <CookieModal isOpen={modalOpen} onClose={closeModal} />
        {/* <Zendesk  defer zendeskKey={ZENDESK_KEY}  /> */}
        <Routes key={location.pathname} location={location}>



          {/* Wallet  */}
          <Route path="/" element={<WalletLayout />} > 
         
         
            <Route path="/wallet/index" element={ 
            <ProtectedRoute> 
              <BWMainPage />  
            </ProtectedRoute>}   /> 

            <Route path="/wallet/notifications" element={ 
            <ProtectedRoute> 
              <Notifications />  
            </ProtectedRoute>}   /> 

            
            <Route path="/wallet/crypto-details" element={ 
             <ProtectedRoute> 
               <CryptoDetails    /> 
              </ProtectedRoute> } />

              <Route path="/wallet/change-pin" element={ 
             <ProtectedRoute> 
               <ChangeCardPin    /> 
              </ProtectedRoute> } />

            <Route path="/wallet/fiat-dashboard" element={ 
             <ProtectedRoute> 
               <FiatDashboard    /> 
              </ProtectedRoute> } />

            <Route path="/wallet/fiat-details" element={ 
             <ProtectedRoute> 
               <FiatDetails    /> 
              </ProtectedRoute> } />

            <Route path="/wallet/crypto" element={ 
             <ProtectedRoute> 
               <WalletCrypto    /> 
              </ProtectedRoute> } />

            <Route path="/wallet/recieved-crypto" element={ 
             <ProtectedRoute> 
               <RecievedCrypto    /> 
             </ProtectedRoute> } />

            
             <Route path="/wallet/send-crypto" element={ 
             <ProtectedRoute> 
               <SendCrypto    /> 
             </ProtectedRoute> } />

             <Route path="/wallet/bills-and-payments" element={ 
             <ProtectedRoute> 
               <BiilsAndPayments    /> 
             </ProtectedRoute> } />

             <Route path="/wallet/send-crypto/via/network" element={ 
             <ProtectedRoute> 
               <SendViaCryptoNetwork    /> 
             </ProtectedRoute> } />

             <Route path="/wallet/recipients-managment" element={ 
             <ProtectedRoute> 
               <RecipientsManagment    /> 
             </ProtectedRoute> } />

             <Route path="/wallet/payment-history" element={ 
             <ProtectedRoute> 
               <TransactionsHistory    /> 
             </ProtectedRoute> } />


             <Route path="/wallet/convert-history" element={ 
             <ProtectedRoute> 
               <ConvertHistory    /> 
             </ProtectedRoute> } />


             <Route path="/wallet/transaction-history" element={ 
             <ProtectedRoute> 
               <TransactionCryptoHistory    /> 
             </ProtectedRoute> } />  
            
             <Route path="/wallet/bills-managment" element={ 
             <ProtectedRoute> 
               <BillsManagment    /> 
             </ProtectedRoute> } />
             
             <Route path="/wallet/send-crypto/via/others" element={ 
             <ProtectedRoute> 
               <SendViaEmailPhoneId    /> 
             </ProtectedRoute> } />

            <Route path="/wallet/swap" element={ 
             <ProtectedRoute> 
               <Swap    /> 
              </ProtectedRoute> } />

              <Route path="/wallet/fiat-swap" element={ 
             <ProtectedRoute> 
               <FiatSwap    /> 
              </ProtectedRoute> } />
            
              <Route path="/wallet/swap-confirm" element={ 
             <ProtectedRoute> 
               <ConfirmSwap    /> 
              </ProtectedRoute> } />

              <Route path="/wallet/swap-success" element={ 
             <ProtectedRoute> 
               <SwapSuccess    /> 
              </ProtectedRoute> } />

            <Route path="/wallet/card-details" element={ 
             <ProtectedRoute> 
               <CardDetails    /> 
              </ProtectedRoute> } />

              <Route path="/wallet/apply-for-upgrade" element={ 
             <ProtectedRoute> 
               <ApplyForUpgrade    /> 
              </ProtectedRoute> } />

            <Route path="/wallet/payments" element={ 
             <ProtectedRoute> 
               <Payment    /> 
              </ProtectedRoute> } />

            <Route path="/wallet/fiat-transfer" element={ 
             <ProtectedRoute> 
               <FiatTransfer  />
              </ProtectedRoute> } />

            <Route path="/wallet/payment-confirmation" element={ 
             <ProtectedRoute> 
               <PaymentConfirmation    /> 
              </ProtectedRoute> } />

              <Route path="/wallet/bill-paid-confirmation" element={ 
             <ProtectedRoute> 
               <BillSuccess    /> 
              </ProtectedRoute> } />

            <Route path="/wallet/account-information" element={ 
             <ProtectedRoute> 
               <Account    /> 
              </ProtectedRoute> } />

            <Route path="/wallet/change-Phone-number" element={ 
            <ProtectedRoute> 
              <ChangePhNumber    /> 
            </ProtectedRoute> } />
          </Route>



          {/* Login  */}
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:uidb64/:token" element={<SetNewPassword />} />
          <Route path="/password-success/" element={<PasswordSuccessView />} />
          <Route path="/verify-otp/" element={<VerifyPassword />} />
          <Route path="/account-type/" element={<AccountSelection />} />
          <Route path="personal/" element={<PersonalAcc />}/>
          <Route path="buisness/" element={<BuisnessAcc />}/>
          <Route path="application/" element={<ApplicationRecevied />}/>


          
            

          <Route path="/sign-up" element={<Signup />} />
          


          <Route path="/" element={<Layout />}>
            {/* Pages  */}
            <Route index element={<HomePage />}/>
            <Route path="personal-account/" element={<PersonalAccount />}/>
            <Route path="Savings-account/" element={<SavingsAcoount />}/>
            <Route path="buisness-account/" element={<BuisnessAccount />}/>
            <Route path="crypto/" element={<Crypto />}/>
            <Route path="about-us/" element={<AboutUs />}/>
            <Route path="cards/" element={<Cards />}/>
            <Route path="contact-us/" element={<ContactUs />}/>
            <Route path="blog/" element={<BlogStructureNew />}/>
            <Route path="blog-details/:string/" element={<BlogDetailsStructure />}/>
            <Route path="digital-wallet/" element={<DigitalWallet />}/>
            <Route path="digital-wallet/" element={<DigitalWallet />}/>
            <Route path="careers/" element={<Careers />}/>
            <Route path="security-bugs/" element={<SecuirityBugs />}/>
            <Route path="security/" element={<Security />}/>

            <Route path="complaints/" element={<Complaints />}/>
            <Route path="legal-terms-policy/" element={<LegalTermsPolicy />}/>
            <Route path="terms-of-use/" element={<TermsOfUse />}/>
            <Route path="privacy-policy/" element={<PrivacyPolicy />}/>
            <Route path="exchange-rates/:str/to/:str/" element={<ExchangeRate />}/>
            <Route path="send-to-country/:str/to/:str/" element={<SendToCountry />}/>



            {/* Activation of Account  */}
            
            <Route path="/activate/:id/:token" element={<AccountActivation/>} />
            <Route path="/account-verification/" element={<AccountVerification/>} />

            {/* Personal Account Sign Up  */}
            <Route path="register/" element={<Register />} />
            <Route path="register/personal/personal-details/" element={<PersonalAccRegisterForm />} />
            <Route path="register/savings/savings-details/" element={<SavingsAccRegisterForm />} />
            <Route path="register/personal/contact/" element={<RegisrterPersonalAccStepTwo />} />
            <Route path="register/personal/address/" element={<RegisrterPersonalAccStepThree />} />

            {/* Buisness Account Sign up  */}
            <Route path="register/buisness/personal-information/" element={<BuisnessAccRegisterForm />} />
            <Route path="register/buisness/buisness-information/" element={<RegisrterBuisnessAccStepTwo />} />
            <Route path="register/buisness/buisness-address/" element={<RegisrterBuisnessAccStepThree />} />
            <Route path="register/buisness/financial-information/" element={<RegisrterBuisnessAccStepFour />} />


          </Route>
          {/* Catch-all route for 404 Not Found */}
          <Route path="*" element={<NotFound />} />
          <Route path="/not-access" element={<NotAccessPage />} />

        </Routes>
        </AnimatePresence>
    </div>
  );
}

export default App;
