import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'; // useParams for uidb64 and token
import { toast } from 'react-toastify'; // for displaying success/error messages
import { usePasswordChangeMutation } from '../../../services/userAuthApi'; // Import the mutation hook

export default function SetNewPassword() {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isErrors, setIsErrors] = useState(false);

  const { uidb64, token } = useParams(); // Get uidb64 and token from URL
  const navigate = useNavigate();

  // Use the mutation hook from userAuthApi.js
  const [resetPassword] = usePasswordChangeMutation();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError(''); // Clear the error message when password changes
    setIsErrors(false);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setError(''); // Clear the error message when confirm password changes
    setIsErrors(false);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (password && confirmPassword) {
      setLoading(true);

      if (password !== confirmPassword) {
        setError("Password and confirm password don't match.");
        setIsErrors(true);
        setLoading(false);
      } else {
        try {
          const response = await resetPassword({ uidb64, token, new_password: password });

          if (response.error) {
            setError(response.error?.data?.message || "Error resetting password");
            setIsErrors(true);
          } else {
            toast.success('Password reset successfully!');
            navigate('/password-success'); // Redirect to success page on success
          }
        } catch (err) {
          setError("An error occurred while resetting the password.");
          setIsErrors(true);
        }
        setLoading(false);
      }
    }
  };

  const goBack = () => {
    navigate('/login'); // This navigates back to the login page
  };

  return (
    <div>
      <div className='container-fluid LoginMainDiv ps-0'>
        <div className='row ps-0'>
          <div className='col-6 px-0 LoginMainDivBg001 d-lg-block d-none ps-0'>
            <div className='d-flex flex-column justify-content-between new_login_main_div h-100'>
              <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_back_icon.svg"} alt="Back" className="back_icon" onClick={goBack} />
              <div>
                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_login_logo.svg"} alt="Logo" className="new_login_logo mb-3" />
                <h1 className='new_heading'>The Future of Banking</h1>
              </div>
            </div>
          </div>

          <div className='col-xxl-6 col-xl-6 col-lg-6 rightSideDivLogin py-md-5 py-0'>
          <div className="d-flex justify-content-center">
            <Link to="/">
              <img loading="lazy" alt="Logo" src={process.env.PUBLIC_URL + "/images/logo.svg"} className="imgResponsive pt-lg-3 pt-5" />
            </Link>
          </div>
            <h2 className='loginTitle'>Set New Password</h2>
            <p className='subTextInForgotPasswordPage px-5'>
              Your new password must be different from previously used passwords.
            </p>

            {isErrors && (
              <div className='error_login_div d-flex gap-3 justify-content-start align-items-center mx-auto my-4'>
                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" />
                <span className='new_error_text'>{error}</span>
              </div>
            )}

            <form onSubmit={handleFormSubmit} className='SignInForm'>
              <div className="mb-5">
                <label htmlFor="password" className="new_input_feilds_label">New Password</label>
                <input
                  type="password"
                  className="new_input_feilds"
                  id="password"
                  placeholder="***********"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                  maxLength="50"
                />
              </div>

              <div className="mb-5">
                <label htmlFor="confirmPassword" className="new_input_feilds_label">Confirm Password</label>
                <input
                  type="password"
                  className="new_input_feilds"
                  id="confirmPassword"
                  placeholder="***********"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                  maxLength="50"
                />
              </div>

              <div className="authSubmitBtn mt-5" style={{ width: '100%' }}>
                <button type="submit" className={`button ${loading ? 'loading' : ''}`} style={{ border: "none" }}>
                  {loading ? <div className="spinner"></div> : <span>Reset Password</span>}
                </button>
              </div>

              <div className='d-flex gap-2 align-items-center backToPage' onClick={goBack}>
                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Group 6.svg"} alt="Back" />
                <p className='m-0 backText'>Back to log in</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
