import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { usePasswordResetEmailMutation } from '../../../services/userAuthApi'; // Using the email reset hook
import { toast } from 'react-toastify';

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isError, setIsError] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  // Using the RTK Query hook to trigger the password reset email API
  const [emailResetApi] = usePasswordResetEmailMutation();

  // Handle email form submission to trigger password reset email
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (email) {
      setLoading(true);
      const resetEmail = { email: email };
      const res = await emailResetApi(resetEmail);
  
      if (res.error) {
        const errorMessage = res?.error?.data?.message || 'An error occurred. Please try again.';
        setError(errorMessage);
        setIsError(true);
        setLoading(false);
      } else if (res.data) {
        // Handle success scenario
        toast.success("Password reset email sent successfully. Please check your inbox.");
        setIsEmailVerified(true);
  
        setEmail('');
  
        setLoading(false);
      }
    }
  };
  
  

  // Handle input change for email
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError(''); // Clear error message when email changes
    setIsError(false);
  };

  // Navigate back to login
  const goBack = () => {
    navigate(-1); // This navigates back one step in the application's history
  };

  return (
    <div className="container-fluid LoginMainDiv ps-0">
      <div className="row ps-0">
        <div className="col-6 px-0 LoginMainDivBg001 d-lg-block d-none ps-0">
          <div className="d-flex flex-column justify-content-between new_login_main_div h-100">
            <img loading="lazy" src={process.env.PUBLIC_URL + "/images/new_back_icon.svg"} alt="Back Icon" className="back_icon" onClick={goBack} />
            <div>
              <img loading="lazy" src={process.env.PUBLIC_URL + "/images/new_login_logo.svg"} alt="Logo" className="new_login_logo mb-3" />
              <h1 className="new_heading">The Future of Banking</h1>
            </div>
          </div>
        </div>

        <div className="col-xxl-6 col-xl-6 col-lg-6 rightSideDivLogin py-md-5 py-0">
          <div className="d-flex justify-content-center">
            <Link to="/">
              <img loading="lazy" alt="Logo" src={process.env.PUBLIC_URL + "/images/logo.svg"} className="imgResponsive pt-lg-3 pt-5" />
            </Link>
          </div>

          <h2 className="loginTitle">Recover Your Password</h2>
          <p className="subTextInForgotPasswordPage px-5">
            Enter the email you used to register, and we'll send a link to reset your password.
          </p>

          {isError && (
            <div className="error_login_div d-flex gap-3 justify-content-start align-items-center mx-auto my-4">
              <img loading="lazy" src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" />
              <span className="new_error_text">{error}</span>
            </div>
          )}

          <form onSubmit={handleFormSubmit} className="SignInForm">
            <div className="mb-5">
              <label htmlFor="email" className="new_input_feilds_label">Enter Your Email</label>
              <input
                type="email"
                className={`new_input_feilds`}
                id="email"
                placeholder="Enter Your Email"
                value={email}
                onChange={handleEmailChange}
                required
                maxLength="50"
              />
            </div>

            <div className="authSubmitBtn mt-5" style={{ width: '100%' }}>
              <button type="submit" className={`button ${loading ? 'loading' : ''}`} style={{ border: 'none' }}>
                {loading ? <div className="spinner"></div> : <span>Forget Password</span>}
              </button>
            </div>

            <div className="d-flex gap-2 align-items-center backToPage" onClick={goBack}>
              <img loading="lazy" src={process.env.PUBLIC_URL + "/images/Group 6.svg"} alt="Back" />
              <p className="m-0 backText">Back to log in</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
